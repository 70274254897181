import React, { useContext, useEffect, useState } from "react";
import "./TokenViewerImg.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { ReactComponent as Casa } from "../../assets/casa.svg";
import { ReactComponent as Distrito } from "../../assets/distrito.svg";
import { ReactComponent as Color } from "../../assets/color.svg";
import { ReactComponent as Objeto } from "../../assets/objeto.svg";
import { ReactComponent as Animal } from "../../assets/animal.svg";
import { ReactComponent as Terreno } from "../../assets/terreno.svg";
import { ReactComponent as Tipo } from "../../assets/tipo.svg";
import { ReactComponent as Underground } from "../../assets/underground.svg";
import { ReactComponent as Vehiculo } from "../../assets/vehiculo.svg";
import DownloadSVG from "../../assets/downloadPink.png";
import callApi from "../../helpers/conectorApi";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";

import { Decrypt, Encrypt } from "../../helpers/myCryptoJS";

export default function TokenViewerImg(props) {
  const [fbxFile, setfbxFile] = useState();
  const [downloading, setDownloading] = useState(false);
  const urlPlatzee =
    "https://rarible.com/token/polygon/0xfec50dae05902f4a1c303da8fb7477f7dea751d5:";
  const tokenData = props.data;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }
  const download3d = () => {
    get3DFile(tokenData.tokenId);
  };
  const get3DFile = async (id) => {
    setDownloading(true);
    try {
      let response = await callApi(
        `/platzeefiles`,
        {
          method: "POST",

          body: JSON.stringify({ key: id + ".fbx" }),
        },
        false
      );
      let bytesfbx = response.data;
      setfbxFile(bytesfbx);
    } catch (error) {
      swal(
        "Oops!",
        "Si estas viendo este mensaje es porque aun se estan subiendo los archivos 3D al servidor. Intenta nuevamente en unos minutos!",
        "error"
      );
    }
  };

  useEffect(() => {
    if (fbxFile) {
      window.open(fbxFile);
      setDownloading(false);
    }
    return () => {};
  }, [fbxFile]);

  const resize = () => {
    const { height } = getWindowDimensions();
  };

  useEffect(() => {
    window.addEventListener("resize", resize);

    const el = document.querySelector("#AppPlatzee");

    el.classList.remove("App-background");
  });

  useEffect(() => {
    resize();
    return () => {};
  }, []);

  const findAttributeValue = (array, att) => {
    let founded = array.filter(function (i, n) {
      return i.trait_type === att;
    });
    if (founded) return founded[0].value;
    else return "";
  };

  return (
    <div className="modelItem"   id={`modelItmid${tokenData.tokenId}` }>
      <div className="front-face">
        <div className="contents front">
          <img
            alt={tokenData.tokenId}
            src={tokenData.image}
            className="ModelPlatzeeImg"
            width="70%"
            id={`modelPlatzee`}
          ></img>

          <p className="info-token-num-front">#{tokenData.tokenId}</p>
        </div>
      </div>
      <div className="back-face">
        <div className="contents back">
          {downloading && (
            <img
              src={DownloadSVG}
              className="arrow-circle-down bounce iconDownload"
            ></img>
          )}
          <div className="info">
            <div className="info-body">
              <p className="info-title">{tokenData.name}</p>
              <p className="info-token-num ">
                {" "}
                <a href={urlPlatzee + tokenData.tokenId} target="_blank">
                  #{tokenData.tokenId}
                </a>
              </p>
              <div className="info-token-listinfo">
                <div className="info-token-listItemAttr">
                  <div className="info-token-listItemAttr-Attribute">
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}1` }>
                      <Casa className="icon-color-attribute"></Casa>
                      <span>
                        {findAttributeValue(tokenData.attributes, "HOUSE")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}2` }>
                      <Distrito className="icon-color-attribute"></Distrito>
                      <span>
                        {findAttributeValue(tokenData.attributes, "DISTRICT")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}3` }>
                      <Color className="icon-color-attribute"></Color>
                      <span>
                        {findAttributeValue(tokenData.attributes, "BACKGROUND")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}4` }>
                      <Objeto className="icon-color-attribute"></Objeto>
                      <span>
                        {findAttributeValue(
                          tokenData.attributes,
                          "RANDOM OBJECT"
                        )}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}5` }>
                      <Animal className="icon-color-attribute"></Animal>
                      <span>
                        {findAttributeValue(tokenData.attributes, "ANIMAL")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}6` }>
                      <Terreno className="icon-color-attribute"></Terreno>
                      <span>
                        {findAttributeValue(tokenData.attributes, "TERRAIN")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}7` }>
                      <Tipo className="icon-color-attribute"></Tipo>
                      <span>
                        {findAttributeValue(tokenData.attributes, "TYPE")}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}8` }>
                      <Underground className="icon-color-attribute"></Underground>
                      <span>
                        {findAttributeValue(
                          tokenData.attributes,
                          "UNDERGROUND"
                        )}
                      </span>
                    </div>
                    <div className="attribute-Item" id={`attr${tokenData.tokenId}9` }>
                      <Vehiculo className="icon-color-attribute"></Vehiculo>
                      <span>
                        {findAttributeValue(tokenData.attributes, "VEHICLE")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="info-Download">
                  <div className="info-token-listItem">
                    <div>
                      {" "}
                      <strong>3D File:</strong>
                    </div>
                    <div>
                      <Link
                        to={`/viewer/${Encrypt(tokenData.tokenId)}`}
                        query={{ idToken: tokenData.tokenId }}
                        className="TokenItem-link"
                      >
                        Viewer
                      </Link>
                    </div>
                    <div>
                      <a
                        href="#"
                        onClick={download3d}
                        className="TokenItem-link"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                  <div className="info-token-listItem">
                    <div>
                      {" "}
                      <strong>Image:</strong>
                    </div>
                    <div>
                      <a
                        href={tokenData.imageTransparent}
                        download={tokenData.tokenId + "Transparent.PNG"}
                        className="TokenItem-link "
                        target="_blank"
                      >
                        Transparent
                      </a>
                    </div>
                    <div>
                      <a
                        href={tokenData.image}
                        download={tokenData.tokenId + ".PNG"}
                        className="TokenItem-link"
                        alt="Download image with background"
                        target="_blank"
                      >
                        Color
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
