import callApi from "../../helpers/conectorApi.js";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Col, Row, Table } from "react-bootstrap";
import "./Admin.css";
import PlatzeeABI from "../../contracts/Platzee.json";
const CONTRACT_ADDRESS = "0xfec50dae05902f4a1c303da8fb7477f7dea751d5";
function compareNumbers(a, b) {
  return a - b;
}

const DataTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Table striped bordered hover className="w-100">
      <thead>
        <tr>
          <th onClick={() => requestSort("quantity")}>Cantidad de tokens</th>
          <th>Wallet</th>
          <th onClick={() => requestSort("fecha_crea")}>Fecha registro</th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item, index) => (
          <tr key={index}>
            <td>{item.quantity}</td>
            <td>{item.wallet}</td>
            <td>{item.fecha_crea}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export default function Admin() {
  const [regs, setregs] = useState();
  const [finalRegs, setfinalRegs] = useState([]);
  const [statistics, setstatistics] = useState({
    wallets: "-",
    tokens: 0
  });
  const [error, seterror] = useState();
  const [loadingRegs, setloadingRegs] = useState(true);
  const [startDate, setStartDate] = useState("2024-10-01");
  const [endDate, setEndDate] = useState(getTodayDate());
  useEffect(() => {
    getByWallets(startDate, endDate);
    return () => {};
  }, []);

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const getByWallets = async (dateIni, dateFin) => {
    try {
      let response = await callApi(
        `/regdividend/AllFilters?startDate=${dateIni}&endDate=${dateFin}`,
        {},
        false
      );
      if (response.data) {
        setregs(response.data.rows);
      }
    } catch (error) {
      setloadingRegs(false);
      seterror("Error en obtener la informacion.");
    }
  };

  useEffect(() => {
    if (endDate && startDate) {
      getByWallets(startDate, endDate);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (regs) {
      updateArray(regs)
        .then((updatedArray) => {
          setloadingRegs(false);
          var groupedArray = groupByQuantity(updatedArray);
          groupedArray.sort((a, b) => b.quantity - a.quantity);
          setstatistics(countTokens(groupedArray));
          setfinalRegs(updatedArray);
          // Use updatedArray as needed
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    return () => {};
  }, [regs]);

  function decodeHexResponse(hexResponse) {
    const cleanHex = hexResponse.startsWith("0x")
      ? hexResponse.slice(2)
      : hexResponse;

    const integers = [];
    for (let i = 0; i < cleanHex.length; i += 64) {
      // 64 hex digits = 32 bytes = uint256
      const hexChunk = cleanHex.substr(i, 64);
      // Convert the hex string directly to a string representation of the number
      integers.push(parseInt(hexChunk, 16).toString());
    }
    return integers;
  }

  const getTokenByOwner = async (walletAddress) => {
    const ALCHEMY_API_URL =
      "https://polygon-mainnet.g.alchemy.com/v2/WrMNd8araTp-pP5-ePLXJgSCs_7975y3"; // Replace with your Alchemy API key

    const url = ALCHEMY_API_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    // Access the ABI array
    const abi = PlatzeeABI.abi; // Access the ABI array from the imported object
    const methodName = "tokenByOwner"; // Replace with your actual method name

    // Find the method in the ABI
    const method = abi.find(
      (item) => item.name === methodName && item.type === "function"
    );

    if (!method) {
      console.error("Method not found in ABI");
      return;
    }

    // Calculate the method signature
    const methodSignature = ethers.utils
      .id(`${methodName}(address)`)
      .slice(0, 10);

    const body = JSON.stringify({
      jsonrpc: "2.0",
      id: 1,
      method: "eth_call",
      params: [
        {
          to: CONTRACT_ADDRESS,
          data: methodSignature + walletAddress.slice(2).padStart(64, "0") // Format the wallet address correctly
        },
        "latest"
      ]
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body
      });
      const data = await response.json();

      // Extract the data part from the response
      const result = data.result;

      // Check if the result exists
      if (result) {
        const decodeTokens = decodeHexResponse(result);
        let tokenIds = [];
        if (decodeTokens) {
          decodeTokens.map((item, index) => {
            tokenIds.push(parseInt(item._hex, 16));
          });
          tokenIds.sort(compareNumbers);
        }
        return tokenIds;
      } else {
        console.error("No result in response:", data);
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const groupByQuantity = (array) => {
    return array.reduce((groups, item) => {
      const { quantity, ...rest } = item;
      const group = groups.find((group) => group.quantity === quantity);
      if (group) {
        group.items.push(rest);
      } else {
        groups.push({ quantity, items: [rest] });
      }
      return groups;
    }, []);
  };

  function countTokens(data) {
    let totalCount = 0;
    let totalQuantity = 0;
    data.forEach((group) => {
      group.items.forEach((item) => {
        totalCount += item.tokenlist.length;
        totalQuantity += 1;
      });
    });
    return { tokens: totalCount, wallets: totalQuantity };
  }

  const updateArray = async (originalArray) => {
    try {
      const updatedArray = await Promise.all(
        originalArray.map(async (item) => {
          const tokenlist = await getTokenByOwner(item.wallet);
          //Introduce delay before making the request
          let reg = {
            ...item,
            tokenlist,
            quantity: tokenlist.length
          };

          return reg;
        })
      );
      return updatedArray;
    } catch (error) {
      console.error("Error updating array:", error);
      return [];
    }
  };
  return (
    <div className="ModelPlatzeeContainer">
      <Row className="w-100">
        <Col xs="6">
          <Form.Group className="form-group font-weight-bolder">
            <Form.Label>Fecha inicial</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="form-control txt-fg-password"
            />
          </Form.Group>
        </Col>
        <Col xs="6">
          <Form.Group className="form-group font-weight-bolder">
            <Form.Label>Fecha Final</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="form-control txt-fg-password"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="modeListStatistics">
        <Col className="modelListItemStatistics">
          <div>{statistics.wallets}</div>
          <div>Wallets</div>
        </Col>
        <Col className="modelListItemStatistics">
          <div>{statistics.tokens}</div>
          <div>Tokens</div>
        </Col>
        <Col className="modelListItemStatistics">
          <div>{statistics.tokens ? statistics.tokens * 42.5 : 0}</div>
          <div>USDT</div>
        </Col>
        <Col className="modelListItemStatistics">
          <div>0</div>
          <div>Pagado</div>
        </Col>
      </Row>
      <Row className="w-100">
        <DataTable data={finalRegs}></DataTable>
      </Row>
    </div>
  );
}
