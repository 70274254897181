import React, { useEffect, useState } from "react";
import TokenViewer from "../TokenViewer/TokenViewer";
import TokenViewerImg from "../TokenViewerImg/TokenViewerImg";
import "./TokenList.css";
import { Card, Form, Col, Row, Button, InputGroup } from "react-bootstrap";
import { Decrypt, Encrypt } from "../../helpers/myCryptoJS";
import { QRCodeCanvas } from "qrcode.react";

import Loader from "../Loader/Loader.js";
import callApi from "../../helpers/conectorApi";
export default function TokenList(props) {
  const [currentAccount, setCurrentAccount] = useState(props.currentAccount);
  const [loadingRegister, setloadingRegister] = useState(true);
  const [alredyReg, setalredyReg] = useState(true);
  const [uid, setuid] = useState();
  const [error, seterror] = useState(null);

  const subject = "Platzee, Codigo de seguimiento";
  const body = `Este es tu codigo de seguimiento: ${uid}`;
  const mailtoLink = `mailto:?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(uid)}`;

  useEffect(() => {
    getById();
    return () => {};
  }, []);
  const getById = async () => {
    try {
      let response = await callApi(
        "/regdividend/" + Encrypt(currentAccount),
        {},
        false
      );
      setalredyReg(response.data.record);
      setloadingRegister(false);
    } catch (error) {
      setloadingRegister(false);
      seterror("Error en obtener la informacion.");
    }
  };

  const registerWallet = async () => {
    try {
      setloadingRegister(true);
      let response = await callApi(
        `/regdividend`,
        {
          method: "POST",
          body: JSON.stringify({
            wallet: currentAccount
          })
        },
        true
      );
      if (response.error == false) {
        setalredyReg(response.data.record);
        setuid(response.data.uii);
      } else {
        seterror(response.data);
      }
      setloadingRegister(false);
    } catch (error) {
      setloadingRegister(false);
      seterror("Error en registro");
    }
  };

  return (
    <div className="ModelPlatzeeContainer">
      <div className="ModelPlatzeeContainerHeader">
        Mis Platzees
        <div className="App-currentAcoount">
          {`Wallet Connected: ${currentAccount.substring(
            0,
            6
          )}...${currentAccount.substring(currentAccount.length - 4)}`}
        </div>
      </div>

      <div className="ModelPlatzeeRedeem"> </div>
      <div className="ModelPlatzeeRedeemBody">
        {loadingRegister ? (
          <Loader></Loader>
        ) : alredyReg || error ? (
          <div className="ModelPlatzeeRedeemBody-Titulo">
            <p>
              {error
                ? `Ya se ha cerrado el registro para redimir tus dividendos. `
                : "Ya estas registrado."}
            </p>
            <p>
              {error
                ? `Solicita apoyo en el canal de discord para verificar tu registro`
                : "Tu billetera digital y todos tus Platzees están registrados. A partir del 1 de noviembre, te enviaremos tus dividendos a la billetera digital que acabas de conectar. ¡Te mandamos un fuerte abrazo, Platzee Holder!"}
            </p>
            {uid && <QRCodeCanvas value={uid} size={256} />}
            <p>
              {uid &&
                `Toma una captura de pantalla o escanea el código QR. Guárdalo por si necesitas un seguimiento más rápido por parte de nuestro equipo de soporte..`}
            </p>
          </div>
        ) : (
          <div className="ModelPlatzeeRedeem">
            <div>
              En esta página encontrarás un listado de todos tus Platzees.
              ¡Presiona el botón morado para conectar tus Platzees y recibir tus
              dividendos a partir de 1 de Noviembre!
            </div>
            <br></br>
            <Button
              variant=""
              className="btn btn-fp"
              onClick={(e) => {
                registerWallet();
              }}
              type="submit"
            >
              Click para registrar!
            </Button>
          </div>
        )}
      </div>

      {props.data.map((item, index) => {
        return (
          <TokenViewerImg
            data={item}
            key={`tvi${index}`}
            id={`tvi${index}`}
          ></TokenViewerImg>
        );
      })}
    </div>
  );
}
