import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import logo from "./logo.svg";

import "./App.css";

import logoPlatzee from "./assets/Logo.png";
import TokenContainer2 from "./components/TokenContainer/TokenContainer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TokenViewer2 from "./components/TokenViewer/TokenViewer";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Admin from "./components/Admin/Admin";
import ArrowLeft from "./assets/arrow-left.svg";
import Mute from "./assets/muteBlack.png";
import Volume from "./assets/volumeBlack.png";
import Loader from "./components/Loader/Loader";
import PlatzeeSound from "./assets/PlatzeeSound.wav";
const TokenContainer = lazy(() =>
  import("./components/TokenContainer/TokenContainer")
);

const TokenViewerFBX = lazy(() =>
  import("./components/TokenViewerFBX/TokenViewerFBX")
);

export default function App() {
  const [step, setStep] = useState(1);

  const [dataSound, setSound] = useState([]);
  const [musicArray] = useState([PlatzeeSound]);
  const changeStep = (step) => {
    setStep(step);
  };
  const goBack = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    configureSounds();

    return () => {};
  }, []);

  const playSound = (index) => {
    setSound((arr) =>
      arr.map((sound, i) => {
        if (i === index) {
          sound.audio.play();
          return { ...sound, play: true };
        }

        return { ...sound, play: false };
      })
    );
  };

  const stopSound = (index) => {
    setSound((arr) =>
      arr.map((sound, i) => {
        if (i === index) {
          sound.audio.pause();
          return { ...sound, play: false };
        }
        return { ...sound, play: false };
      })
    );
  };

  const configureSounds = () => {
    const musicData = musicArray.map((sound) => {
      return { audio: new Audio(sound), play: false };
    });

    setSound(musicData);
  };
  return (
    /*    <div id="AppPlatzee" className={"App App-background"}>*/
    <div id="AppPlatzee" className={"App "}>
      <div className="App-logo">
        <div className="App-actionBtn">
          {step === 2 && (
            <img
              src={ArrowLeft}
              alt="Platzee"
              className="img-fluid app-action-back"
              onClick={goBack}
            ></img>
          )}
        </div>
        <a href="https://www.platzees.io" target="_blank">
          <img
            src={logoPlatzee}
            alt="Platzee"
            className="img-fluid img-clickable platzeeLogo"
          ></img>
        </a>
        <div className="App-actionBtn btnSound">
          {dataSound &&
            dataSound.map((sound, i) => {
              return sound.play ? (
                <img
                  id={`appIm${i}`}
                  key={`appIm${i}`}
                  src={Volume}
                  alt="Platzee"
                  className="img-fluid app-action-back"
                  onClick={() => stopSound(i)}
                ></img>
              ) : (
                <img
                  src={Mute}
                  key={`appIme${i}`}
                  id={`appIme${i}`}
                  alt="Platzee"
                  className="img-fluid app-action-back"
                  onClick={() => playSound(i)}
                ></img>
              );
            })}
        </div>
      </div>

      <div className="App-Body">
        <Router>
          <Suspense fallback={<Loader></Loader>}>
            <Routes>
              <Route path="/admin" element={<Admin></Admin>}></Route>
              <Route
                path="/"
                element={<TokenContainer setStep={changeStep}></TokenContainer>}
              ></Route>
              <Route
                path="/viewer/:id"
                element={
                  <TokenViewerFBX setStep={changeStep}> </TokenViewerFBX>
                }
              ></Route>
              <Route
                path="/forgotpassword/:id"
                element={<ForgotPassword setStep={changeStep}></ForgotPassword>}
              ></Route>
            </Routes>
          </Suspense>
        </Router>
      </div>
    </div>
  );
}
