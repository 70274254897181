import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { Card, Form, Col, Row, Button, InputGroup } from "react-bootstrap";

import "./ForgotPassword.css";
import callApi from "../../helpers/conectorApi";
import config from "../../config";
import { useParams } from "react-router-dom";
export default function ForgotPassword(props) {
  const params = useParams();
  const [loading, setloading] = useState(false);
  const [showError, setshowError] = useState("");
  const [varianteMensaje, setvarianteMensaje] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    const el = document.querySelector("#AppPlatzee");

    el.classList.remove("App-background");

    if (!(params && params.id)) {
      console.log("no hay params");
    }
    return () => {};
  }, []);

  const onSubmit = (dat) => {
    setshowError("");
    setloading(true);
    var idreq = "";
    if (params && params.id) {
      idreq = params.id;
    } else {
      alert("nohay");
    }
    var dataSend = {
      id: idreq,
      password: dat.newPass,
      password_confirmar: dat.confirmPass
    };
    fetch(`${config.urlApiGame}/api/resetpass`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json"
      },
      body: JSON.stringify(dataSend)
    })
      .then((response) =>
        Promise.all([response.ok, response.json(), response.status])
      )
      .then(([responseOk, body, status]) => {
        if (responseOk) {
          let { code, data } = body.body;
          if (code === 1) {
            setvarianteMensaje("success");
            setshowError(data);
          } else {
            setvarianteMensaje("danger");
            setshowError(data);
          }
          setloading(false);
        } else {
          setloading(false);
          setshowError(body.data);
        }
      })
      .catch((err) => {
        setloading(false);
        setvarianteMensaje("danger");
        setshowError(
          "Error con la comunicacion, comuniquese con el administrador."
        );
      });
  };

  return (
    <form className="divForgotPasswordt" onSubmit={handleSubmit(onSubmit)}>
      <Card className="cardFP">
        <Card.Body>
          <Row>
            <Col xs="12">
              <Form.Group className="form-group font-weight-bolder">
                <Form.Label>Contraseña Nueva</Form.Label>
                <Form.Control
                  type="password"
                  defaultValue=""
                  className="form-control txt-fg-password"
                  placeholder="Contraseña nueva"
                  {...register("newPass", { required: "Password is required" })}
                />

                {errors.newPass && (
                  <span className="span-error">{errors.newPass.message}</span>
                )}
              </Form.Group>
              <Form.Group className="form-group font-weight-bolder">
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  defaultValue=""
                  className="form-control txt-fg-password"
                  placeholder="Confirmar Contraseña"
                  {...register("confirmPass", {
                    required: "Password is required",
                    validate: (val) => {
                      if (watch("newPass") != val) {
                        return "Your passwords do no match";
                      }
                    }
                  })}
                />
                {errors.confirmPass && (
                  <span className="span-error">
                    {errors.confirmPass.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="form-group font-weight-bolder">
                <Button
                  variant=""
                  className="btn btn-fp"
                  type="submit"
                >
                  Actualizar
                </Button>
              </Form.Group>
              <Form.Group className="form-group font-weight-bolder">
                <Button
                  variant=""
                  className="btn btn-fp-danger"
                  onClick={(e) => {
                    window.location.href = "https://3d.platzees.io/";
                  }}
                >
                  Cancelar
                </Button>
              </Form.Group>{" "}
              {showError.length > 0 ? (
                <Alert
                  variant={varianteMensaje}
                  className={`span-${varianteMensaje}`}
                >
                  {showError}
                </Alert>
              ) : (
                <p></p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </form>
  );
}
