import "./TokenContainer.css";
import swal from "sweetalert";
import ReactDOM from "react-dom";

import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../Loader/Loader.js";
import TokenList from "../TokenList/TokenList";
import myEpicNft from "../../utils/MyEpicNFT.json";

const CONTRACT_ADDRESS = "0xfec50dae05902f4a1c303da8fb7477f7dea751d5";

const chainNeeded = "0x89";
const TWITTER_HANDLE = "platzees";
const MUMBAI_LINK = "https://polygonscan.com/tx/";

function compareNumbers(a, b) {
  return a - b;
}
function TokenContainer(props) {
  const [loading, setloading] = useState(true);
  const [validNetwork, setvalidNetwork] = useState(false);
  const [currentAccount, setCurrentAccount] = useState("");
  const [tokensId, settokensId] = useState([]);
  const [tokenData, settokenData] = useState([]);

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      let wrapper = document.createElement("div");
      ReactDOM.render(
        <p>
          Para que puedas conectar tu billetera es necesario que te encuentres
          en la red de Polygon. En esta página encontrarás todos los videos para
          entender cómo funciona el proceso, paso a paso. Haz clic donde dice
          'video tutoriales' .Agregar el boton 'Video tutoriales' que tire aca:{" "}
          <a href="https://www.platzees.io/conectar">
            https://www.platzees.io/conectar
          </a>
        </p>,
        wrapper
      );
      let el = wrapper.firstChild;

      swal({
        title: "¡Estás en la red equivocada!",
        content: el,
        icon: "error"
      });
      return;
    } else {
    }
    let chainId = await ethereum.request({ method: "eth_chainId" });

    if (chainId !== chainNeeded) {
      setvalidNetwork(false);
      let wrapper = document.createElement("div");
      ReactDOM.render(
        <p>
          Para que puedas conectar tu billetera es necesario que te encuentres
          en la red de Polygon. En esta página encontrarás todos los videos para
          entender cómo funciona el proceso, paso a paso. Haz clic donde dice
          'video tutoriales' .Agregar el boton 'Video tutoriales' que tire aca:{" "}
          <a href="https://www.platzees.io/conectar">
            https://www.platzees.io/conectar
          </a>
        </p>,
        wrapper
      );
      let el = wrapper.firstChild;

      swal({
        title: "¡Estás en la red equivocada!",
        content: el,
        icon: "error"
      });
      return;
    } else {
      setvalidNetwork(true);
    }
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0) {
      const account = accounts[0];

      setCurrentAccount(account);

      // Setup listener! This is for the case where a user comes to our site
      // and ALREADY had their wallet connected + authorized.
      setupEventListener();
    }
    //else {
    //  swal("Oops!", "No authorized account found", "error");
    //}

    //setUriContract();
  };

  // Setup our listener.
  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;

      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          myEpicNft.abi,
          signer
        );
      } else {
        swal("Oops!", "Ethereum object doesn't exist!", "error");
      }
    } catch (error) {}
  };

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        swal(
          "Oops!",
          "Utiliza un navegador que tenga instalado metamask.",
          "error"
        );
        return;
      }

      if (!validNetwork) {
        let wrapper = document.createElement("div");
        ReactDOM.render(
          <p>
            Para que puedas conectar tu billetera es necesario que te encuentres
            en la red de Polygon. En esta página encontrarás todos los videos
            para entender cómo funciona el proceso, paso a paso. Haz clic donde
            dice 'video tutoriales' .Agregar el boton 'Video tutoriales' que
            tire aca:{" "}
            <a href="https://www.platzees.io/conectar">
              https://www.platzees.io/conectar
            </a>
          </p>,
          wrapper
        );
        let el = wrapper.firstChild;

        swal({
          title: "¡Estás en la red equivocada!",
          content: el,
          icon: "error"
        });
        return;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts"
      });

      setCurrentAccount(accounts[0]);
      getTokens();
      // Setup listener! This is for the case where a user comes to our site
      // and connected their wallet for the first time.
      setupEventListener();

      setloading(true);
    } catch (error) {}
  };

  const getTokens = async () => {
    try {
      setloading(true);
      const { ethereum } = window;

      if (!ethereum) {
        swal(
          "Oops!",
          "Utiliza un navegador que tenga instalado metamask.",
          "error"
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        myEpicNft.abi,
        signer
      );
      let nftTxn = await connectedContract.tokenByOwner(currentAccount);
      let tokenIds = [];
      nftTxn.map((item, index) => {
        tokenIds.push(parseInt(item._hex, 16));
      });
      tokenIds.sort(compareNumbers);
      settokensId(tokenIds);
    } catch (error) {}
  };

  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className="cta-button connect-wallet-button "
    >
      Conecta tu wallet
    </button>
  );

  useEffect(() => {
    props.setStep(1);
    checkIfWalletIsConnected();
  }, []);

  const getTokenData = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        swal(
          "Oops!",
          "Utiliza un navegador que tenga instalado metamask.",
          "error"
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        myEpicNft.abi,
        signer
      );

      let tokenD = [];
      for (const item of tokensId) {
        const tokenUri = await connectedContract.tokenURI(item);
        let jsdonItem = await fetch(
          tokenUri.replace("ipfs://", "https://platzees.mypinata.cloud/ipfs/")
        ).then((res) => res.json());
        jsdonItem.image = jsdonItem.image.replace(
          "ipfs://",
          "https://platzees.mypinata.cloud/ipfs/"
        );
        jsdonItem.imageTransparent = jsdonItem.imageTransparent.replace(
          "ipfs://",
          "https://platzees.mypinata.cloud/ipfs/"
        );
        tokenD.push({ ...jsdonItem, tokenId: item });
      }
      settokenData(tokenD);
      setloading(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (tokensId) {
      getTokenData();
    }

    return () => {};
  }, [tokensId]);

  useEffect(() => {
    if (currentAccount) {
      getTokens();
    }
  }, [currentAccount]);

  return (
    <>
      {loading && (
        <>
          <Loader></Loader>
          <div>
            <div className="App-tb-container">
              <div className="App-tb">
                <div className="App-tb-paragraph">
                  <br></br>
                  En este momento estamos identificando la información de tu
                  billetera digital de Metamask. Éste proceso podría tardar un
                  par de minutos. Si ha pasado más tiempo que eso, por favor
                  actualiza esta página e inicia el proceso de nuevo.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!loading && currentAccount === "" && (
        <>
          <div className="App-connect-mint">
            <div className="App-tb-container">
              <div className="App-tb">
                <div className="App-tb-title">¡Bienvenido Platzee Holder!</div>
                <div className="App-tb-paragraph">
                  Esperamos que te encuentres en excelente. Ha pasado
                  aproximadamente un año desde que lanzamos nuestra emocionante
                  <b> colección Platzees de NFT's</b> al mercado, y estamos
                  felices que ha sido mucho éxito. Creemos que nuestro arduo
                  trabajo, dedicación y enfoque nos han permitido construir una
                  colección de excelencia respaldada al 100% por más de 180
                  propiedades en Estados Unidos que generan ingresos de rentas
                  mensuales.
                </div>
                <div className="App-tb-paragraph">
                  Nos complace cumplir con lo que prometimos desde el
                  lanzamiento de la colección. Como mencionamos, ofrecemos un
                  retorno de inversión en mayo de <b>2023</b>, ¡y ahora es una
                  realidad! Para que puedas recibir tus dividendos, es
                  importante que registres tu wallet en nuestra página web{" "}
                  <b>Platzees</b>. Los dividendos serán transferidos en{" "}
                  <b>WUSDT’s </b>
                  para tu comodidad y transparencia.
                </div>
                <div className="App-tb-paragraph">
                  Para asegurarnos de que el proceso de registro sea fácil y
                  exitoso, nuestro <b>CEO Rodrigo Blanco</b> ha grabado una
                  serie de videos informativos detallando los pasos que debes
                  seguir. Puedes encontrar estos videos en nuestra página web
                  oficial, en nuestro servidor de Discord, y también te los
                  hemos enviado por correo electrónico para asegurarnos de que
                  los recibas.
                </div>
                <div className="App-tb-paragraph">
                  Estamos emocionados de poder recompensar tu confianza en
                  nuestra colección de <b>Platzees</b> y esperamos que disfrutes
                  de los dividendos que te corresponden. ¡Gracias por ser parte
                  de esta increíble aventura con nosotros!
                </div>
                <div className="App-tb-paragraph">
                  <b>El equipo de Platzees</b>
                </div>
              </div>
            </div>
            {renderNotConnectedContainer()}
          </div>
        </>
      )}

      {!loading && tokenData.length > 0 && (
        <div className="ModelContainerMaster">
          <TokenList
            data={tokenData}
            currentAccount={currentAccount}
          ></TokenList>
        </div>
      )}
    </>
  );
}

export default TokenContainer;
